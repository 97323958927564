<template>
  <div style="padding: 18px;">
    <v-col cols="1" style="text-align:end;padding-top:0px;padding-bottom:0px;padding-left:0px;">
      <input-money
        :label="$t('label.total')"
        disabled
        v-model="acao.valor">
      </input-money>
    </v-col>
    <v-data-table
      :headers="cabecalho"
      :items="paginaServicos"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 30],
      }"
      dense>
      <template v-slot:item="{ item, index }">
          <tr>
            <td class="justify-center px-0">
              <v-tooltip bottom v-if="canEdit && !somenteLeitura">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click.stop="editar(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.editar') }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="canEdit && !somenteLeitura">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="excluir(item, index)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.excluir') }}</span>
              </v-tooltip>
            </td>
            <td>
              <span>
                {{ getTipo(item.tipo) }}
              </span>
            </td>
            <td>
              <span>
                {{ item.descricao }}
              </span>
            </td>

            <td>
              <span>
                {{ item.ncm }}
              </span>
            </td>

            <td>
              <span>
                {{ item.quantidade }}
              </span>
            </td>
            <td>
              <span>
                {{ getMoney(item.vlrunitario) }}
              </span>
            </td>
            <td>
              <span>
                {{ calcularTotal(item) }}
              </span>
            </td>
          </tr>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="4" style="padding-bottom:0px;padding-left:0px;">
        <v-text-field
          dense
          id="localEntrega"
          v-model.trim="localEntrega"
          @input="alteracaoEntrega"
          maxlenght="50"
          counter="50"
          :disabled="somenteLeitura"
          :label="`${$tc('label.local_entrega', 1)}`"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" style="padding-bottom:0px;padding-left:0px;">
        <v-text-field
          dense
          id="enderecoEntrega"
          v-model.trim="enderecoEntrega"
          maxlenght="500"
          counter="500"
          @input="alteracaoEntrega"
          :disabled="somenteLeitura"
          :label="`${$tc('label.endereco_entrega', 1)}`"/>
      </v-col>
      <v-col style="text-align: end;">
        <v-btn fab
          bottom right
          class="v-btn__fab"
          color="primary"
          @click.native="abrirModalServico()"
          v-show="canEdit && isStatusEmAnaliseOuEmCadastro && !somenteLeitura">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>
    <planejamento-acao-form-modal-servico
      ref="modalFormServico"
      :somenteLeitura="somenteLeitura"
      @SALVAR_SERVICO="adicionar"
      @RECALCULAR_TOTAL="recalcularValorAcao"/>
  </div>
</template>
<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { getMoney } from '../../../common/functions/helpers';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import PlanejamentoAcaoFormModalServico from './PlanejamentoAcaoFormModalServico';

export default {
  name: 'PlanejamentoAcaoFormDetalhesAcao',
  props: {
    acao: {
      type: Object,
      default: {},
    },
    tipoAcao: {
      type: Object,
      required: true,
    },
    somenteLeitura: Boolean,
    novaAcao: Boolean,
  },
  components: {
    Confirm,
    InputMoney,
    PlanejamentoAcaoFormModalServico,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        if (!this.novaAcao && !this.possuiServicoAdicionadoNaoSalvo) {
          this.buscar();
        } else {
          this.calcularPaginacao();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      planejamentoAcaoServicoResources: this.$api.resourcePlanejamentoAcaoServico(this.$resource),

      servicos: [],
      pagination: {},
      lastPagination: {},
      paginaServicos: [],
      totalPage: 0,
      cabecalho: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', width: '4%', sortable: true,
        },
        {
          text: this.$tc('label.tipo', 1), value: 'tipo', width: '6%', sortable: true,
        },
        {
          text: this.$tc('label.descricao', 1), width: '20%', value: 'descricao', sortable: true,
        },
        {
          text: this.$tc('label.ncm', 1), width: '8%', value: 'ncm', sortable: false,
        },
        {
          text: this.$tc('label.quantidade', 1), width: '7%', value: 'quantidade', sortable: false,
        },
        {
          text: this.$tc('label.valor_unitario', 1), width: '9%', value: 'vlrunitario', sortable: false,
        },
        {
          text: this.$tc('label.valor_total', 1), width: '10%', value: 'vlrTotal', sortable: false,
        },
      ],
      localEntrega: this.$t('label.local_entrega_padrao'),
      enderecoEntrega: this.$t('label.endereco_entrega_padrao'),
    };
  },
  computed: {
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    isStatusEmAnaliseOuEmCadastro() {
      return this.acao.status === 'Em cadastro'
        || this.acao.status === 'Em análise';
    },
    possuiServicoAdicionadoNaoSalvo() {
      return this.servicos.filter((s) => !s.id).length > 0;
    },
  },
  methods: {
    getMoney,
    getTipo(tipo) {
      return tipo === 'PURCHASE' ? 'Purchase' : 'Framework';
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    buscar() {
      if (!this.novaAcao) {
        const params = {
          idAcao: this.$route.params.idAcao,
          page: this.pagination.page,
          size: this.pagination.itemsPerPage,
        };
        this.planejamentoAcaoServicoResources.buscar(params)
          .then((res) => {
            this.servicos = res.data.resposta;
            this.calcularPaginacao();
            const s = this.servicos[0];
            if (s) {
              this.localEntrega = s.localentrega;
              this.enderecoEntrega = s.enderecoentrega;
            }
          }, (err) => {
            this.$error(this, err);
            this.$toast(this.$t(err));
          });
      }
    },
    calcularPaginacao() {
      this.totalPage = this.servicos.length;
      const min = this.pagination.itemsPerPage * (this.pagination.page - 1);
      const max = min + this.pagination.itemsPerPage;
      this.paginaServicos = this.servicos.filter((s, i) => i >= min && i < max);
    },
    adicionar(servico) {
      this.servicos.push(servico);
      this.calcularPaginacao();
      this.recalcularValorAcao();
      this.servicos.forEach((s) => {
        s.localentrega = this.localEntrega;
        s.enderecoentrega = this.enderecoEntrega;
      });
    },
    excluir(item, index) {
      if (!this.novaAcao) {
        this.servicos.splice(index, 1);
        this.planejamentoAcaoServicoResources.deletar({ id: item.id })
          .then(() => {
          }, (err) => {
            this.$error(this, err);
            this.$toast(this.$t(err));
          });
      } else {
        const idx = (this.pagination.itemsPerPage * (this.pagination.page - 1)) + index;
        this.servicos.splice(idx, 1);
      }
      this.calcularPaginacao();
      this.recalcularValorAcao();
    },
    editar(item) {
      this.$refs.modalFormServico.open(item);
    },
    abrirModalServico() {
      this.$refs.modalFormServico.open();
    },
    calcularTotal(item) {
      return this.getMoney(item.quantidade * item.vlrunitario);
    },
    recalcularValorAcao() {
      if (!this.servicos.length) {
        this.acao.valor = 0;
      } else {
        this.acao.valor = this.servicos.map((item) => item.quantidade * item.vlrunitario)
          .reduce((prev, next) => prev + next);
      }
      this.$emit('PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao', this.acao.valor);
    },
    getServicos() {
      return this.servicos;
    },
    alteracaoEntrega() {
      this.servicos.forEach((s) => {
        s.localentrega = this.localEntrega;
        s.enderecoentrega = this.enderecoEntrega;
      });
    },
    validaSecaoDetalheAcao() {
      if (!this.servicos.length) {
        this.$toast(this.$t('errors.adicione_um_servico'));
      }
      return this.servicos.length > 0;
    },
  },
  mounted() {
    if (!this.novaAcao) {
      this.buscar();
    }
  },
};
</script>
<style>
.PlanejamentoAcaoTabela__ColunaGrande {
  min-width: 210px;
}
.PlanejamentoAcaoTabela__ColunaMedia {
  min-width: 120px;
}
</style>
