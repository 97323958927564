<style>
.PlanejamentoAcaoFormApuracao .v-text-field:not(.error--text) .v-text-field__details {
  display: none;
}
.PlanejamentoAcaoFormApuracao .title-float {
  font-weight: 400;
  font-size: 20px;
}
</style>
<template>
  <div class="PlanejamentoAcaoFormApuracao">
    <planejamento-acao-form-resumo
      :exibe-cliente-pagador="exibeClientePagador"
      :exibe-fornecedor-pagador="exibeFornecedorPagador"
      :objeto-passo-geral="objetoPassoGeral"
      :ordenacao-formulario="ordenacaoFormulario"
      :somenteLeitura="somenteLeitura"
      ref="resumo">
      <div slot="titulo-acao">
        <slot name="titulo-acao" />
      </div>
    </planejamento-acao-form-resumo>

    <div class="mt-2 mb-2 title-float accent--text">
      {{$tc('label.apuracao', 1)}}
    </div>
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" :md="metaIntervalo ? 6 : 12" class="pa-0">
          <v-card style="height: 100%;">
            <v-container fluid grid-list-md>
              <v-row>
                <v-col cols="12" :md="metaIntervalo ? 6 : 4">
                  <v-autocomplete
                    class="custom-autocomplete"
                    v-model="formulario.periodosApuracao"
                    clearable
                    chips
                    multiple
                    deletable-chips
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    :items="periodosApuracaoPermitidos"
                    :label="`${$tc('label.periodo_apuracao',1)} *`"
                    item-text="nome"
                    item-value="valor"
                    :item-disabled="desabilitarPeriodo"
                    :rules="[rules.requiredAny]"
                    :disabled="somenteLeitura || periodosApuracaoPermitidos.length === 1"
                    @change="periodoApuracaoAlterado">
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="2" v-if="valorAcaoTemplateValorTotalAcao && !comMeta">
                  <input-money
                    :label="$tc('label.valor', 1)"
                    v-model="formulario.coeficienteValorTotal"
                    class="v-text-field"
                    @input="calcularValorTotal"
                    :disabled="somenteLeitura"/>
                </v-col>
                <v-col cols="4" md="2" v-if="valorAcaoTemplateValorTotalAcao">
                  <input-money
                    :label="$tc('label.valor_acao', 1)"
                    v-model="objetoPassoGeral.valor"
                    class="v-text-field"
                    disabled/>
                </v-col>
                <v-col cols="12" :md="metaIntervalo ? 6 : 4" v-if="metaAutomatica && comMeta && temCalculosApuracoes">
                  <v-autocomplete
                    class="custom-autocomplete"
                    v-model="formulario.calculoApuracao"
                    clearable
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    :items="calculosApuracoesPermitidos"
                    :label="`${$tc('label.calculo_apuracao',1)} *`"
                    item-text="nome"
                    item-value="valor"
                    :rules="[rules.required]"
                    :disabled="somenteLeitura || calculosApuracoesPermitidos.length === 1"
                    @change="calculoApuracaoAlterado">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" :md="metaIntervalo ? 6 : 4" v-if="metaAutomatica && comMeta && calculoVolume">
                  <v-autocomplete
                    id="unidade_medida"
                    name="unidade_medida"
                    v-model="formulario.unidadeMedida"
                    clearable
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    :items="unidadesMedidasPermitidas"
                    :label="`${$tc('label.unidade_medida', 1)} *`"
                    item-text="nomUnidade"
                    item-value="id"
                    :disabled="somenteLeitura || unidadesMedidasPermitidas.length === 1"
                    :rules="[rules.required]"
                    @input="unidadeSelecionada">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" :md="metaIntervalo ? 6 : 4" v-if="metaAutomatica && comMeta && !metaIntervalo">
                  <input-money
                    v-if="calculoValor && !unidadeQuantidade"
                    :label="$tc('label.meta', 1)"
                    v-model="formulario.meta"
                    :disabled="somenteLeitura"
                    class="v-text-field"/>
                  <v-text-field
                    v-else-if="calculoVolume && !unidadeQuantidade"
                    :label="$tc('label.meta', 1)"
                    v-model="formulario.meta"
                    :disabled="somenteLeitura"
                    type="Number"
                    min="0"
                    :suffix="sigla"/>
                  <v-text-field
                    v-else-if="calculoPercentual && !unidadeQuantidade"
                    :label="$tc('label.meta', 1)"
                    v-model="formulario.meta"
                    :disabled="somenteLeitura"
                    type="Number"
                    min="0"
                    suffix="%"/>
                  <v-text-field
                    v-else
                    :label="$tc('label.meta', 1)"
                    v-model="formulario.meta"
                    :disabled="somenteLeitura"
                    type="Number"
                    min="0"/>
                </v-col>
                <v-col cols="12" :md="metaIntervalo ? 6 : 4" v-if="metaAutomatica && metaIntervalo">
                  <v-autocomplete
                    class="custom-autocomplete"
                    v-model="formulario.formaBonificacao"
                    clearable
                    :no-data-text="`${$tc('message.nenhum_registro', 1)} *`"
                    :items="formasBonificacaoPermitidas"
                    :label="`${$tc('label.forma_bonificacao',2)} *`"
                    item-text="nome"
                    item-value="valor"
                    :rules="[rules.required]"
                    @change="formaBonificacaoSelecionada"
                    :disabled="somenteLeitura || formasBonificacaoPermitidas.length === 1">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-3" v-if="metaIntervalo">
          <planejamento-acao-form-apuracao-intervalo
            :somente-leitura="somenteLeitura"
            :calculo-volume="calculoVolume"
            :calculo-valor="calculoValor"
            :calculo-percentual="calculoPercentual"
            :unidade-quantidade="metaPorQuantidade"
            :destacar-meta="destacarMeta"
            :sigla="sigla"
            :verba-percentual="verbaPercentual"
            :metas="formulario.metas"
            @PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAte="alterarCoeficienteCalculoValorTotalAcao"
            @retirarDestaque="() => destacarMeta = false"
          />
        </v-col>
      </v-row>
    </v-container>

      <div class="mt-3 mb-2 title-float accent--text">
        {{ indModoVarejo ? $tc('label.recebimento', 1) : $tc('label.pagamento', 1) }}
      </div>
      <v-card>
        <v-container fluid grid-list-md>
          <v-row>
            <v-col cols="12" md="3" v-if="metaAutomatica && !metaIntervalo">
              <v-autocomplete
                id="verba"
                class="custom-autocomplete"
                v-model="formulario.formaBonificacao"
                clearable
                :no-data-text="`${$tc('message.nenhum_registro', 1)} *`"
                :items="formasBonificacaoPermitidas"
                :label="`${$tc('label.forma_bonificacao',2)} *`"
                item-text="nome"
                item-value="valor"
                @change="formaBonificacaoSelecionada"
                :rules="[rules.required]"
                :disabled="somenteLeitura || formasBonificacaoPermitidas.length === 1">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" v-if="metaAutomatica && temCalculosBonificacoesPermitidos && !verbaFixa">
              <v-autocomplete
                id="calculo_bonificacao"
                class="custom-autocomplete"
                v-model="formulario.calculoBonificacao"
                clearable
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :items="calculosBonificacoesPermitidos"
                :label="`${$tc('label.calculo_bonificacao',1)} *`"
                item-text="nome"
                item-value="valor"
                :rules="[rules.required]"
                :disabled="somenteLeitura || calculosBonificacoesPermitidos.length === 1">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" v-if="metaAutomatica && temBasesCalculoPermitidas && !metaNAFixa">
              <v-autocomplete
                id="base_calculo"
                class="custom-autocomplete"
                v-model="formulario.baseCalculo"
                clearable
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :items="basesCalculoPermitidas"
                :label="`${$tc('label.base_calculo',2)} *`"
                item-text="nome"
                item-value="valor"
                :rules="[rules.required]"
                :disabled="somenteLeitura || basesCalculoPermitidas.length === 1"
                @input="baseSelecionada">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" d-flex v-if="tiposPagamentoPermitidos.length">
              <v-autocomplete
                id="tipo_pagamento"
                class="custom-autocomplete"
                v-model="formulario.tipoPagamento"
                clearable
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :items="tiposPagamentoPermitidos"
                :label="`${$tc('label.tipo_pagamento',2)} *`"
                item-text="nome"
                item-value="valor"
                :rules="[rules.required]"
                :disabled="somenteLeitura || tiposPagamentoPermitidos.length === 1">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3"
               v-if="exibeValorBonificacao">
              <v-text-field
                v-if="verbaPercentual"
                :label="$tc('label.valor', 1)"
                v-model="formulario.valorBonificacao"
                suffix="%"
                :disabled="somenteLeitura"
                type="Number"
                min="0"
              ></v-text-field>
              <input-money
                v-else
                :label="$tc('label.valor', 1)"
                v-model="formulario.valorBonificacao"
                :disabled="somenteLeitura || indValorManualMetaNAFixo"
                @input="alterarCoeficienteCalculoValorTotalAcao(formulario.valorBonificacao)"
                :rules="rules.requiredValor(formulario.valorBonificacao, metaNaoAplica, metaPorQuantidade, verbaFixa)"
                class="v-text-field"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <div v-show="exibeDescontos">
        <div class="mt-3 mb-2 title-float accent--text">
          {{$tc('label.desconto', 2)}}
        </div>
        <v-card>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12" sm="6" md="3" v-for="(m, index) in descontos" :key="index">
                <v-checkbox
                  v-model="formulario.descontos"
                  :label="$tc(`label.${m.toLowerCase()}`, 1)"
                  :value="m"
                  :disabled="somenteLeitura">
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>

    <planejamento-acao-form-apuracao-excecao
      ref="apuracaoExcecao"
      :configuracao="configuracao"
      :somenteLeitura="somenteLeitura"
      v-if="exibeExcecoes">
    </planejamento-acao-form-apuracao-excecao>

    <div v-show="exibeAnexoFornecedor">
      <div class="mt-3 mb-2 title-float accent--text">
        {{$tc('label.anexo', 2)}}
      </div>
      <v-card>
        <v-container fluid grid-list-md>
          <planejamento-acao-form-anexo-arquivo
            :acao="acaoEdicao"
            :habilita-anexo-fornecedor="habilitaAnexoFornecedor"
            :configuracao="configuracao"
            :nova-acao="novaAcao">
          </planejamento-acao-form-anexo-arquivo>
        </v-container>
      </v-card>
    </div>

    <confirm ref="confirm"
      :message="dialog.message"
      :hide-disagree="dialog.hideDisagree"
      :persistent="true"
      :agree-label="dialog.agreeLabel"
      @agree="dialog.agree"
      @disagree="dialog.disagree">
    </confirm>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoFormResumo from './PlanejamentoAcaoFormResumo';
import PlanejamentoAcaoFormApuracaoExcecao from './PlanejamentoAcaoFormApuracaoExcecao';
import PlanejamentoAcaoFormApuracaoIntervalo from './PlanejamentoAcaoFormApuracaoIntervalo';
import PlanejamentoAcaoFormAnexoArquivo from './PlanejamentoAcaoFormAnexoArquivo';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import PlanejamentoAcaoCalculoValorTotalAcaoMixin from './planejamentoAcaoCalculoValorTotalAcaoMixin';

export default {
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    tipoAcao: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  mixins: [PlanejamentoAcaoCalculoValorTotalAcaoMixin],
  components: {
    Confirm,
    PlanejamentoAcaoFormResumo,
    PlanejamentoAcaoFormApuracaoExcecao,
    PlanejamentoAcaoFormApuracaoIntervalo,
    PlanejamentoAcaoFormAnexoArquivo,
    InputMoney,
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    exibeClientePagador() {
      return !!this.configuracao.habilitaClientePagador && this.objetoPassoGeral.focoPagamento === 'CLIENTE';
    },
    exibeFornecedorPagador() {
      return !!this.configuracao.habilitaFornecedorPagador && this.objetoPassoGeral.focoPagamento === 'FORNECEDOR';
    },
    periodosApuracaoPermitidos() {
      const { periodosApuracao } = this.configuracao;
      Object.keys(periodosApuracao).forEach((key) => {
        if (periodosApuracao[key] === null) {
          delete periodosApuracao[key];
        }
      });

      if (periodosApuracao != null) {
        return this.listaPeriodosApuracao
          .filter((p) => periodosApuracao.indexOf(p.valor) >= 0);
      }
      return {};
    },
    calculosBonificacoesPermitidos() {
      const { calculosBonificacao } = this.configuracao;
      return this.listaCalculosBonificacoes
        .filter((p) => calculosBonificacao.indexOf(p.valor) >= 0);
    },
    temCalculosBonificacoesPermitidos() {
      return this.calculosBonificacoesPermitidos
        && this.calculosBonificacoesPermitidos.length;
    },
    basesCalculoPermitidas() {
      const { basesCalculo } = this.configuracao;
      return this.listaBaseCalculo
        .filter((p) => basesCalculo.indexOf(p.valor) >= 0);
    },
    temBasesCalculoPermitidas() {
      return this.basesCalculoPermitidas
        && this.basesCalculoPermitidas.length;
    },
    formasBonificacaoPermitidas() {
      const { bonificacoes } = this.configuracao;
      return this.listaFormaBonificacao
        .filter((p) => bonificacoes.indexOf(p.valor) >= 0);
    },
    tiposPagamentoPermitidos() {
      // if (this.objetoPassoGeral.fornecedorPagador
      //   && this.objetoPassoGeral.fornecedorPagador.codTipoBeneficio) {
      //   return this.tiposPagamento
      //     .filter((p) => this.objetoPassoGeral.fornecedorPagador.codTipoBeneficio
      //       .indexOf(p.codTipo) >= 0)
      //     .map((p) => ({ nome: this.$tc(`tipos_pagamentos.${p.codTipo}`, 1), valor: p.codTipo }));
      // }
      const { pagamentos } = this.configuracao;
      return this.tiposPagamento
        .filter((p) => pagamentos.indexOf(p.id) >= 0)
        .map((p) => ({ nome: this.$tc(`tipos_pagamentos.${p.codTipo}`, 1), valor: p.codTipo }));
    },
    metaAutomatica() {
      return this.configuracao
        && this.configuracao.calculoAutomatico;
    },
    indValorManualMetaNAFixo() {
      return !!(!this.valorAcaoCalculadoAutomaticamente
        && this.metaNAFixa && this.objetoPassoGeral.valor);
    },
    valorAcaoCalculadoAutomaticamente() {
      return this.configuracao
        && this.configuracao.valorAcaoCalculadoAutomaticamente;
    },
    comMeta() {
      return this.configuracao
        && this.configuracao.templateCalculo !== ''
        && this.configuracao.templateCalculo !== 'META_N/A';
    },
    metaNaoAplica() {
      return this.configuracao
        && this.configuracao.templateCalculo === 'META_N/A';
    },
    metaPorQuantidade() {
      return this.configuracao
        && this.configuracao.templateCalculo === 'META_POR_QUANTIDADE';
    },
    valorAcaoTemplateCrescimento() {
      const { configuracao } = this.configuracao;
      return configuracao.passo3.template === 'CRESCIMENTO';
    },
    valorAcaoTemplateValorTotalAcao() {
      const { configuracao } = this.configuracao;
      return configuracao.passo3.template === 'VALOR_TOTAL_ACAO';
    },
    possuiVerbaInvestimento() {
      const { listaIdVerbaInvestimento } = this.configuracao;
      return listaIdVerbaInvestimento && listaIdVerbaInvestimento.length > 0;
    },
    calculosApuracoesPermitidos() {
      const { calculosApuracao } = this.configuracao;
      return this.listaCalculoApuracao
        .filter((p) => calculosApuracao.indexOf(p.valor) >= 0);
    },
    temCalculosApuracoes() {
      return this.calculosApuracoesPermitidos
        && this.calculosApuracoesPermitidos.length;
    },
    calculoVolume() {
      if (this.formulario.calculoApuracao
        && (this.formulario.calculoApuracao === 'SELLIN_VOLUME'
          || this.formulario.calculoApuracao === 'SELLOUT_VOLUME')) {
        return true;
      }
      return false;
    },
    calculoValor() {
      if (this.formulario.calculoApuracao
        && (this.formulario.calculoApuracao === 'SELLIN_VALOR'
          || this.formulario.calculoApuracao === 'SELLOUT_VALOR')) {
        return true;
      }
      return false;
    },
    calculoPercentual() {
      if (this.formulario.calculoApuracao
          && this.formulario.calculoApuracao === 'SELLIN_PERCENTUAL') {
        return true;
      }
      return false;
    },
    unidadeQuantidade() {
      return this.metaPorQuantidade;
    },
    unidadesMedidasPermitidas() {
      const { unidadesMedidas } = this.configuracao;
      return unidadesMedidas;
    },
    metaIntervalo() {
      return this.configuracao
        && this.configuracao.meta === 'INTERVALO';
    },
    verbaPercentual() {
      if (this.formulario.formaBonificacao
        && this.formulario.formaBonificacao === 'PERCENTUAL') {
        return true;
      }
      return false;
    },
    verbaFixa() {
      if (this.formulario.formaBonificacao
        && this.formulario.formaBonificacao === 'FIXO') {
        return true;
      }
      return false;
    },
    metaNAFixa() {
      return this.metaNaoAplica && this.verbaFixa;
    },
    descontos() {
      const { configuracao } = this;
      if (this.formulario.baseCalculo
        && this.formulario.baseCalculo === 'VALOR_LIQUIDO'
        && configuracao.devolucoesHabilitadas) {
        return ['DEVOLUCOES'];
      }
      return configuracao.descontos;
    },
    exibeDescontos() {
      const { configuracao } = this;
      return this.metaAutomatica && this.formulario.baseCalculo
        && ((this.formulario.baseCalculo === 'VALOR_BRUTO'
        && configuracao.descontos && configuracao.descontos.length)
        || (this.formulario.baseCalculo === 'VALOR_LIQUIDO'
        && configuracao.devolucoesHabilitadas));
    },
    exibeExcecoes() {
      const { excecaoCampos } = this.configuracao;
      return excecaoCampos && excecaoCampos.length > 0;
    },
    exibeValorBonificacao() {
      return ((this.valorAcaoCalculadoAutomaticamente
                && (this.valorAcaoTemplateCrescimento || this.valorAcaoTemplateValorTotalAcao))
                || this.metaNaoAplica || this.metaPorQuantidade)
                && !this.metaIntervalo;
    },
    exibeAnexoFornecedor() {
      return this.indModoVarejo;
    },
    habilitaAnexoFornecedor() {
      return this.isStatusEmCadastro
        || (this.isStatusAguardandoAprovacao
          && this.isUsuarioFornecedor
          && this.aprovadorFluxo);
    },
    isUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    isStatusEmCadastro() {
      return this.novaAcao || this.acaoEdicao.passoGeral.status === 'EM_CADASTRO';
    },
    isStatusAguardandoAprovacao() {
      return !this.novaAcao && this.acaoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO';
    },
  },
  watch: {
    indValorManualMetaNAFixo(val) {
      if (val) {
        this.formulario.valorBonificacao = this.objetoPassoGeral.valor;
      }
    },
  },
  data() {
    return {
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredAny: (val) => {
          const msg = this.$t('message.campo_obrigatorio');
          return (val && val.length && val.length > 0) || msg;
        },
        requiredValor: (val, metaNaoAplica, metaPorQuantidade, verbaFixa) => [
          () => {
            if ((!metaNaoAplica && !metaPorQuantidade) || !verbaFixa) {
              return true;
            }

            const msg = this.$t('message.campo_obrigatorio');
            return (!!val && val > 0) || msg;
          },
        ],
      },

      dialog: {
        message: '',
        hideDisagree: false,
        agreeLabel: this.$t('label.sim'),
        agree: () => {},
        disagree: () => {},
      },

      aprovadorFluxo: false,

      destacarMeta: false,

      formaBonificacaoAnterior: null,

      formulario: {
        periodosApuracao: null,
        baseCalculo: null,
        formaBonificacao: null,
        tipoPagamento: null,
        calculoBonificacao: null,
        calculoApuracao: null,
        unidadeMedida: null,
        indPossuiPassoFornecedor: true,
        metas: [],
        descontos: [],
      },

      sigla: '',

      tiposPagamento: [],

      listaPeriodosApuracao: [
        {
          valor: 'ANUAL',
          nome: this.$tc('label.anual'),
        },
        {
          valor: 'SEMESTRAL',
          nome: this.$tc('label.semestral', 1),
        },
        {
          valor: 'TRIMESTRAL',
          nome: this.$tc('label.trimestral'),
        },
        {
          valor: 'MENSAL',
          nome: this.$tc('label.mensal'),
        },
        {
          valor: 'UNICA_VEZ',
          nome: this.$tc('label.unica_vez'),
        },
      ],
      listaCalculosBonificacoes: [
        {
          valor: 'SELLIN',
          nome: this.$tc('label.sellin'),
        },
        {
          valor: 'SELLOUT',
          nome: this.$tc('label.sellout'),
        },
      ],
      listaBaseCalculo: [
        {
          valor: 'VALOR_BRUTO',
          nome: this.$tc('label.valor_bruto'),
        },
        {
          valor: 'VALOR_LIQUIDO',
          nome: this.$tc('label.valor_liquido'),
        },
        {
          valor: 'NOTAS_DEVOLUCAO',
          nome: this.$tc('label.nota_devolucao', 2),
        },
      ],
      listaFormaBonificacao: [
        {
          valor: 'FIXO',
          nome: this.$tc('label.fixo'),
        },
        {
          valor: 'VARIAVEL',
          nome: this.$tc('label.variavel'),
        },
        {
          valor: 'PERCENTUAL',
          nome: this.$tc('label.percentual'),
        },
      ],
      listaCalculoApuracao: [
        {
          valor: 'SELLIN_VALOR',
          nome: this.$tc('label.sellin_valor'),
        },
        {
          valor: 'SELLIN_VOLUME',
          nome: this.$tc('label.sellin_volume'),
        },
        {
          valor: 'SELLOUT_VALOR',
          nome: this.$tc('label.sellout_valor'),
        },
        {
          valor: 'SELLOUT_VOLUME',
          nome: this.$tc('label.sellout_volume'),
        },
        {
          valor: 'QUANTIDADE',
          nome: this.$tc('label.quantidade'),
        },
      ],

    };
  },
  methods: {
    alterarCoeficienteCalculoValorTotalAcao(valor) {
      if (this.valorAcaoTemplateValorTotalAcao) {
        this.formulario.coeficienteValorTotal = valor;
        this.calcularValorTotal();
      }
    },
    desabilitarPeriodo(item) {
      if (this.formulario.periodosApuracao && !!this.formulario.periodosApuracao.length) {
        if (this.formulario.periodosApuracao.includes('UNICA_VEZ')) {
          return item.valor !== 'UNICA_VEZ';
        } if (!this.formulario.periodosApuracao.includes('UNICA_VEZ')) {
          return item.valor === 'UNICA_VEZ';
        }
      }
      return false;
    },
    validacaoCustomizada() {
      return true;
    },
    triggerSelecao(id) {
      setTimeout(() => document.getElementById(id).click());
    },
    buscaAutocomplete(autocomplete, callback) {
      if (typeof autocomplete !== 'string') {
        autocomplete = '';
      }
      return callback(autocomplete);
    },
    buscarTiposPagamento() {
      this.tipoBeneficioResources
        .listarAtivos()
        .then((response) => {
          this.tiposPagamento = response.data;
          setTimeout(this.definirTipoPagamento, 1E2);
        });
    },
    abrePasso() {
      setTimeout(this.$refs.resumo.montaResumo(), 1E3);
    },
    getObjetoFormatado() {
      const objetoFormatado = {
        ...this.formulario,
      };
      if (this.$refs.apuracaoExcecao) {
        this.$refs.apuracaoExcecao.formataExcecoes(objetoFormatado);
      }
      delete objetoFormatado.produto;

      return objetoFormatado;
    },
    montaObjetoAcao() {
      this.formulario = {
        ...this.acaoEdicao.passoComportamento,
        ...this.acaoEdicao.passoComportamento.mapaExcecoes,
        ...this.acaoEdicao.passoComportamento.metas,
      };
      this.objetoPassoGeral.valor = this.acaoEdicao.passoGeral.valor;
      this.formulario.coeficienteValorTotal = this.acaoEdicao.passoGeral.coeficienteValorTotal;

      if (this.indModoVarejo) {
        this.formulario.indPossuiPassoFornecedor = this.objetoPassoGeral.indPossuiPassoFornecedor;
      }

      if (this.$refs.apuracaoExcecao) {
        this.$refs.apuracaoExcecao.montaObjetoAcao(this.acaoEdicao);
      }
    },
    unidadeSelecionada(item) {
      const unidade = this.unidadesMedidasPermitidas.filter((u) => u.id === item);
      if (unidade && unidade.length) {
        this.sigla = unidade[0].sigla;
      }
    },
    periodoApuracaoAlterado() {
      this.$emit('PlanejamentoAcaoFormApuracao__periodoApuracao');
      if (this.valorAcaoTemplateValorTotalAcao) {
        this.calcularValorTotal();
      }
    },
    getValorTotalAcao() {
      return this.objetoPassoGeral.valor;
    },
    getCoeficienteValorTotal() {
      return this.objetoPassoGeral.coeficienteValorTotal;
    },
    valorDaAcaoFoiAlterado() {
      return this.valorAcaoTemplateValorTotalAcao;
    },
    calculoApuracaoAlterado() {
      if (this.calculoVolume && this.unidadesMedidasPermitidas.length === 1) {
        this.formulario.unidadeMedida = this.unidadesMedidasPermitidas[0].id;
      } else {
        this.formulario.unidadeMedida = null;
      }
      this.formulario.meta = 0;
    },
    validarMetas() {
      if (this.metaIntervalo && !this.formulario.metas.length) {
        this.$toast(this.$t('message.intervalo_meta_obrigatorio'));
        return false;
      }
      return true;
    },
    baseSelecionada() {
      this.formulario.descontos = [];
    },
    definirTipoPagamento() {
      // if (this.objetoPassoGeral.fornecedorPagador
      //   && this.objetoPassoGeral.fornecedorPagador.codTipoBeneficio) {
      //   this.formulario.tipoPagamento = this.objetoPassoGeral.fornecedorPagador.codTipoBeneficio;
      // } else
      if (this.tiposPagamentoPermitidos.length === 1) {
        this.formulario.tipoPagamento = this.tiposPagamentoPermitidos[0].valor;
      }
    },
    finalizarSelecaoFormaBonificacao(destacarMeta) {
      if (this.calculosBonificacoesPermitidos.length === 1 && !this.verbaFixa) {
        this.formulario.calculoBonificacao = this.calculosBonificacoesPermitidos[0].valor;
      } else {
        this.formulario.calculoBonificacao = null;
      }
      if (this.basesCalculoPermitidas.length === 1 && !this.metaNAFixa) {
        this.formulario.baseCalculo = this.basesCalculoPermitidas[0].valor;
      } else {
        this.formulario.baseCalculo = null;
      }

      this.formaBonificacaoAnterior = this.formulario.formaBonificacao;
      this.destacarMeta = destacarMeta;
    },
    retornarAFormaBonificacaoAnterior() {
      this.formulario.formaBonificacao = this.formaBonificacaoAnterior;
    },
    formaBonificacaoSelecionada() {
      if (!this.formulario.metas
        || !this.formulario.metas.length) {
        this.finalizarSelecaoFormaBonificacao(false);
      } else {
        this.dialog.message = this.$tc('message.alterar_forma_bonificacao_redefinir_meta', 2);
        this.dialog.agree = () => this.finalizarSelecaoFormaBonificacao(true);
        this.dialog.disagree = this.retornarAFormaBonificacaoAnterior;
        this.dialog.hideDisagree = false;
        this.dialog.agreeLabel = this.$t('label.sim');
        setTimeout(() => this.$refs.confirm.open());
      }
    },
    getObjetoNatural() {
      const objeto = { ...this.formulario };
      return objeto;
    },
    preencherAcaoNova() {
      if (this.periodosApuracaoPermitidos.length === 1) {
        this.formulario.periodosApuracao = [this.periodosApuracaoPermitidos[0].valor];
      }
      if (this.calculosApuracoesPermitidos.length === 1) {
        this.formulario.calculoApuracao = this.calculosApuracoesPermitidos[0].valor;
      }
      if (this.unidadesMedidasPermitidas.length === 1) {
        this.formulario.unidadeMedida = this.unidadesMedidasPermitidas[0].id;
      }
      if (this.formasBonificacaoPermitidas.length === 1) {
        this.formulario.formaBonificacao = this.formasBonificacaoPermitidas[0].valor;
      }
      if (this.calculosBonificacoesPermitidos.length === 1) {
        this.formulario.calculoBonificacao = this.calculosBonificacoesPermitidos[0].valor;
      }
      if (this.basesCalculoPermitidas.length === 1) {
        this.formulario.baseCalculo = this.basesCalculoPermitidas[0].valor;
      }
    },
    verificarUsuarioAprovador(idAcao) {
      if (this.acaoEdicao.passoGeral && this.acaoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO') {
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
  },
  mounted() {
    this.buscarTiposPagamento();
    if (this.novaAcao) {
      this.preencherAcaoNova();
    } else {
      this.montaObjetoAcao();
      this.verificarUsuarioAprovador(this.acaoEdicao.id);
    }
  },
};
</script>
