export default {
  name: 'planejamentoAcaoCalculoValorTotalAcaoMixin',
  data() {
    return {
    };
  },
  computed: {
    possuiPeriodoApuracaoUnicaVez() {
      return this.formulario
        && this.formulario.periodosApuracao
        && this.formulario.periodosApuracao
          .indexOf('UNICA_VEZ') > -1;
    },
    possuiPeriodoApuracaoMensal() {
      return this.formulario
        && this.formulario.periodosApuracao
        && this.formulario.periodosApuracao
          .indexOf('MENSAL') > -1;
    },
    possuiPeriodoApuracaoTrimestral() {
      return this.formulario
        && this.formulario.periodosApuracao
        && this.formulario.periodosApuracao
          .indexOf('TRIMESTRAL') > -1;
    },
    possuiPeriodoApuracaoAnual() {
      return this.formulario
        && this.formulario.periodosApuracao
        && this.formulario.periodosApuracao
          .indexOf('ANUAL') > -1;
    },
  },
  methods: {
    calcularValorTotal() {
      let valorTotal = 0;
      const periodoMensal = 1;
      const periodoTrimestral = 3;
      const periodoAnual = 12;

      if (this.possuiPeriodoApuracaoUnicaVez) {
        valorTotal = this.formulario.coeficienteValorTotalAcao;
      }
      if (this.possuiPeriodoApuracaoMensal) {
        valorTotal += this.calcularValorTotalPorPeriodicidade(periodoMensal);
      }
      if (this.possuiPeriodoApuracaoTrimestral) {
        valorTotal += this.calcularValorTotalPorPeriodicidade(periodoTrimestral);
      }
      if (this.possuiPeriodoApuracaoAnual) {
        valorTotal += this.calcularValorTotalPorPeriodicidade(periodoAnual);
      }
      this.$emit('PlanejamentoAcaoFormApuracao__alteradoValorTotal', valorTotal,
        this.formulario.coeficienteValorTotal);
    },
    calcularValorTotalPorPeriodicidade(periodo) {
      const dtaInicio = this.moment(this.objetoPassoGeral.dtaInicio, 'YYYY-MM-DD');
      const dtaFim = this.moment(this.objetoPassoGeral.dtaFim, 'YYYY-MM-DD');
      const mesDiff = dtaFim.diff(dtaInicio, 'months') + 1;
      let quantidade = Math.floor(mesDiff / periodo);

      if (mesDiff % periodo !== 0) {
        quantidade += 1;
      }
      return quantidade * this.formulario.coeficienteValorTotal;
    },
  },
};
