export default class AcaoComponenteEvento {
  constructor(evento, objeto = null, done = null) {
    this._evento = evento;
    this._objeto = objeto;
    this._done = done;
    this._doneExecutado = false;
    this._acaoFormNaoExecutarDone = true;
    this._proximosPassos = [];
  }

  ehEvento(evento) {
    return this._evento === evento;
  }

  executarDoneAcaoForm() {
    if (this._done && !this._doneExecutado
      && this._acaoFormNaoExecutarDone) {
      this._doneExecutado = true;
      this._done();
    }
  }

  executarDone() {
    if (this._done && !this._doneExecutado) {
      this._done();
    }
    this._doneExecutado = true;
  }

  desabilitarExecucaoDoneAcaoForm() {
    this._acaoFormNaoExecutarDone = false;
  }

  pushProximoPasso(passo) {
    this._proximosPassos.push(passo);
  }

  executarProximoPasso() {
    if (this.temPassoEncadeados) {
      const [referencia] = this._proximosPassos;
      this._proximosPassos.shift();
      referencia.onChainedEvents(this);
    } else {
      this.executarDoneAcaoForm();
    }
  }

  get temPassoEncadeados() {
    return this._proximosPassos && this._proximosPassos.length;
  }

  get evento() {
    return this._evento;
  }

  get objeto() {
    return this._objeto;
  }

  get doneExecutado() {
    return this._doneExecutado;
  }
}
